import { httpClient } from '../api/httpClient';
import { httpCool } from '../api/httpCool';
import { Post } from '../types/Post';

type PostAddType = Pick<Post, 'userId' | 'title' | 'text' | 'image'>;
type PostUpdateType = { id: number }
  & Partial<Pick<Post, 'title' | 'text' | 'image'>>;

async function getById(postId: number): Promise<Post> {
  return httpCool.get(`/posts/${postId}`);
}

async function getByPage(page: number): Promise<Post[]> {
  return httpCool.get(`/posts?page=${page}`);
}

async function post(
  newPost: PostAddType,
): Promise<Post> {
  return httpClient.post('/posts', newPost);
}

async function patch(
  newPost: PostUpdateType,
): Promise<Post> {
  return httpClient.patch(`/posts/${newPost.id}`, newPost);
}

export const postService = {
  getById,
  getByPage,
  post,
  patch,
};
