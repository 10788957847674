import React from 'react';
import styles from './Footer.module.scss';
import { Container } from '../Container';
import { Picture } from '../Picture';

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.content}>
          <div className={styles.leftPanel}>
            <a
              href="https://ednipro.dp.ua"
              className={styles.logoLink}
              target="_blank"
            >
              <Picture
                className={styles.logo}
                src="/icons/ye-dnipro-logo.svg"
                alt="yeDnipro icon"
              />
            </a>
            <p className={styles.rights}>
              Всі права захищенні
            </p>
          </div>

          <div className={styles.rightPanel}>
            <p className={styles.label}>
              Будьте з нами на одній хвилі в соціальних мережах:
            </p>

            <a
              className={styles.iconLink}
              href="https://t.me/kp_ednipro"
              target="_blank"
            >
              <Picture
                className={styles.icon}
                src="/icons/telegram-icon.svg"
                alt="telegram icon"
              />
            </a>

            <a
              className={styles.iconLink}
              href="https://www.facebook.com/kpednipro"
              target="_blank"
            >
              <Picture
                className={styles.icon}
                src="/icons/facebook-icon.svg"
                alt="facebook icon"
              />
            </a>

            <a
              className={styles.iconLink}
              href="https://instagram.com/ednipro"
              target="_blank"
            >
              <Picture
                className={styles.icon}
                src="/icons/instagram-icon.svg"
                alt="instagram icon"
              />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};
