import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

type Props = {
  children?: React.ReactNode;
}

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</> || <Outlet />;
};
