/* eslint-disable max-len */
import React, { useContext, useEffect, useCallback } from 'react';
import styles from './NewPostForm.module.scss';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

import { Post } from '../../../../types/Post';

import { LoadingButton } from '../../../../components/LoadingButton';
import { FileInput } from '../FIleInput';
import { FileContext } from '../../../../contexts/FileContext';
import { PostsContext } from '../../../../contexts/PostsContext';
import { AuthContext } from '../../../../contexts/AuthContext';

import { imageService } from '../../../../services/ImageService';
import { postService } from '../../../../services/PostService';

type UpdatePostType = {
  post: { id: number } & Partial<Pick<Post, 'title' | 'text' | 'image'>>;
  file: File | null;
};

type PostAddType = {
  userId: number | null;
  title: string;
  text: string;
  file: File;
}

type Props = {
  selectedPost: Post | null;
}

export const NewPostForm: React.FC<Props> = ({ selectedPost }) => {
  const { selectedFile, resetFile, error, previewURL, setPreview } = useContext(FileContext);
  const { addPost, updatePost } = useContext(PostsContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (selectedPost) {
      setPreview(selectedPost.image);
    } else {
      resetForm();
    }
  }, [selectedPost]);

  const addPostMutation = useMutation<Post, any, PostAddType & { file: File }>({
    mutationKey: 'addPost',
    mutationFn: async({ userId, title, text, file }) => {
      const image = await imageService.upload(file);
      const newPost = await postService.post({
        userId, title, text, image,
      });

      return newPost;
    },
    onSuccess: (newPost) => {
      addPost(newPost);
      resetForm();
    },
  });

  const updatePostMutation = useMutation<Post, any, UpdatePostType>({
    mutationKey: 'updatePost',
    mutationFn: async({ post, file }) => {
      if (!file) {
        return postService.patch(post);
      }

      const image = await imageService.upload(file);
      const updatedPost = await postService.patch({ ...post, image });

      return updatedPost;
    },
    onSuccess: (updatedPost) => updatePost(updatedPost),
  });

  const formik = useFormik({
    initialValues: {
      title: selectedPost?.title ?? '',
      text: selectedPost?.text ?? '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(255, 'Заголовок повинен бути не бішьше 255 символів')
        .required('Загловок обов\'язковий'),
      text: Yup.string()
        .max(65000, 'Текст повинен бути не бішьше 65 000 символів')
        .required('Текст обов\'язковий'),
    }),
    onSubmit: async(values) => {
      if (selectedPost) {
        handleUpdatePost(values, selectedPost);
      } else {
        handleAddPost(values);
      }
    },
  });

  const resetForm = useCallback(() => {
    formik.resetForm({
      values: {
        title: '',
        text: '',
      },
    });
    resetFile();
  }, []);

  const handleAddPost = async(values: FormikValues) => {
    if (!selectedFile) {
      return;
    }

    const { title, text } = values;

    addPostMutation.mutate({
      userId: user?.id ?? null, title, text, file: selectedFile,
    });
  };

  const handleUpdatePost = async(values: FormikValues, post: Post) => {
    if (!previewURL) {
      return;
    }

    const { title, text } = values;

    updatePostMutation.mutate({
      post: { id: post.id, title, text },
      file: selectedFile,
    });
  };

  return (
    <>
      <div className={styles.topInfo}>
        {selectedPost ? (
          <>
            <h1 className={styles.title}>
              Редагування новини
            </h1>

            <span className={styles.skip}>
              <Link to={'/admin'}>
                Відмінити
              </Link>
            </span>
          </>
        ) : (
          <h1 className={styles.title}>
            Створення новини
          </h1>
        )}
      </div>

      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.container}>
          <TextareaAutosize
            id="title"
            className={styles.field}
            minRows={1}
            placeholder="Заголовок"
            {...formik.getFieldProps('title')}
          />

          {formik.touched.title && formik.errors.title && (
            <div className={styles.validation}>
              {formik.errors.title}
            </div>
          )}
        </div>

        <div className={styles.container}>
          <TextareaAutosize
            id="text"
            className={styles.field}
            minRows={6}
            placeholder="Текст"
            {...formik.getFieldProps('text')}
          />

          {formik.touched.text && formik.errors.text && (
            <div className={styles.validation}>
              {formik.errors.text}
            </div>
          )}
        </div>

        <div className={styles.container}>
          <FileInput />

          <div className={styles.validation}>
            {(formik.submitCount > 0) && !selectedFile && !previewURL && "Зображення обов'язкове "}

            {error}
          </div>
        </div>

        <div className={styles.button}>
          <LoadingButton
            type="submit"
            text={selectedPost ? 'Змінити' : 'Створити'}
            isLoading={addPostMutation.isLoading || updatePostMutation.isLoading}
          />
        </div>

        <div className={styles.validation}>
          {selectedPost ? (
            updatePostMutation.isError && (
              updatePostMutation.error?.response?.data?.message ?? 'Помилка під час редагування новини'
            )
          ) : (
            addPostMutation.isError && (
              addPostMutation.error?.response?.data?.message ?? 'Помилка під час створення новини'
            )
          )}
        </div>
      </form>
    </>
  );
};
