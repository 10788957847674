import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<HTMLImageElement>

export const Picture: React.FC<Props> = ({ ...props }) => {
  return (
    <img
    {...props}
    src={String(process.env.REACT_APP_STATIC_FILES_URL) + props.src}
  />
  );
};
