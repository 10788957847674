import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './AuthPanel.module.scss';
import { Container } from '../Container';
import { AuthContext } from '../../contexts/AuthContext';

export const AuthPanel: React.FC = React.memo(() => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (!user) {
      navigate('/login');
    } else {
      navigate('/admin');
    }
  };

  return (
    <nav className={styles.authPanel}>
      <Container>
        <div
          className={cn(
            styles.content,
            {
              [styles.noUser]: !user,
            },
          )}>
          {user && (
            <button
              className={cn(styles.icon, styles.logout)}
              onClick={logout}>
            </button>
          )}

          <div className={styles.info}>
            <span className={styles.username}>{user?.name}</span>
            <button
              className={cn(styles.icon, styles.profile)}
              onClick={handleLoginClick}>
            </button>
          </div>
        </div>
      </Container>
    </nav>
  );
});
