import React, { useState, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import styles from './LoginPage.module.scss';

import { LoadingButton } from '../../components/LoadingButton';
import { AuthContext } from '../../contexts/AuthContext';
import { FormInput } from '../../components/FormInput';

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [authMessage, setAuthMessage] = useState('');

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const loginMutation = useMutation({
    mutationFn: async() => {
      await login(username, password);
    },
    onSuccess: () => {
      navigate(location.state?.from?.pathname || '/admin');
    },
    onError: (error: any) => {
      setAuthMessage(error?.response?.data?.message
        ?? 'Помилка при перевірці користувача. Перевірте інтрнет з\'єднання');
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!username || !password) {
      return;
    }

    loginMutation.mutate();
  };

  const hadnleUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    }, [],
  );

  const hadnlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    }, [],
  );

  return (
    <main className={styles.main}>
      <form
        className={styles.form}
        onSubmit={handleSubmit}
        noValidate
      >
        <h1 className={styles.title}>
          Зайдіть у систему
        </h1>

        <div className={styles.field}>
          <FormInput
            type="text"
            name="login"
            placeholder="Логін"
            autoComplete="username"
            value={username}
            onChange={hadnleUsernameChange}
            required
            isSubmitted={isSubmitted}
          />
        </div>

        <div className={styles.field}>
          <FormInput
            type="password"
            name="password"
            placeholder="Пароль"
            autoComplete="current-password"
            value={password}
            onChange={hadnlePasswordChange}
            required
            isSubmitted={isSubmitted}
          />
        </div>

        <div className={styles.button}>
          <LoadingButton
            type="submit"
            text="Увійти"
            isLoading={loginMutation.isLoading}
          />
        </div>

        {authMessage && (
          <div className={styles.validation}>
            {authMessage}
          </div>
        )}
      </form>
    </main>
  );
};
