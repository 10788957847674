/* eslint-disable */
import React, { AnchorHTMLAttributes } from 'react';

type Props = AnchorHTMLAttributes<HTMLAnchorElement>

export const PageLink: React.FC<Props> = ({ ...props }) => {
  return (
    <a
      {...props}
      href={String(process.env.REACT_APP_STATIC_FILES_URL) + props.href}
    />
  );
};
