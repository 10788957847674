import React from 'react';
import { Loader } from '../Loader';
import styles from './LoadingButton.module.scss';

type Props = {
  text: string;
  isLoading: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const LoadingButton: React.FC<Props> = React.memo(({
  text,
  isLoading,
  ...props
}) => (
  <button
    className={styles.button}
    {...props}
  >
    {isLoading ? <Loader width={25} height={25} /> : text}
  </button>
));
