import React, { SyntheticEvent, useContext } from 'react';
import styles from './PostAdmin.module.scss';
import { Post } from '../../../../types/Post';
import { getFormattedTime } from '../../../../helpers/getFormattedTime';
import { Picture } from '../../../../components/Picture';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { Loader } from '../../../../components/Loader';
import { useParams, useNavigate } from 'react-router-dom';
import { httpClient } from '../../../../api/httpClient';
import { PostsContext } from '../../../../contexts/PostsContext';
import { PageLink } from '../../../../components/PageLink';

export type Props = {
  post: Post;
}

export const PostAdmin: React.FC<Props> = ({ post }) => {
  const { removePost } = useContext(PostsContext);
  const { postId: selectedPostId } = useParams();
  const navigate = useNavigate();

  const handleNestedLinkClick = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const deletePostQuery = useQuery<void>({
    queryKey: ['post', post.id],
    queryFn: async() => {
      await httpClient.delete(`/posts/${post.id}`);
    },
    enabled: false,
  });

  const handleDeletePost = async(event: SyntheticEvent) => {
    handleNestedLinkClick(event);
    await deletePostQuery.refetch();

    if (!deletePostQuery.isError) {
      removePost(post.id);
      navigate('/admin');
    }
  };

  return (
    <div
      className={classNames(
        styles.item,
        {
          [styles.loading]: deletePostQuery.isLoading,
          [styles.selected]: post.id === Number(selectedPostId),
        },
      )}
      onClick={() => {
        navigate(`/admin/${post.id}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      {deletePostQuery.isFetching && (
        <>
          <div className={styles.overlay}>
            <Loader />
          </div>
        </>
      )}

      <div className={styles.content}>
        <Picture
          className={styles.image}
          src={'/' + post.image}
          alt="post image"
        />

        <div className={styles.info}>
          <PageLink
            href={`/posts/${post.id}`}
            onClick={handleNestedLinkClick}
            target='_blank'
          >
            <h3 className={styles.title}>
              {post.title}
            </h3>
          </PageLink>

          <span className={styles.date}>
            {getFormattedTime(post.date)}
          </span>
        </div>
      </div>

      <div className={styles.edit}>
        <button
          className={styles.close}
          onClick={handleDeletePost}
        >
          <Picture
            className={styles.closeImage}
            src="/icons/remove-icon.png"
            alt="post image"
          />
        </button>
      </div>
    </div>
  );
};
