import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { httpCool } from '../../api/httpCool';

import { Post } from './components/Post';
import { PrettyPost } from './components/PrettyPost';

import styles from './PostPage.module.scss';
import { Post as IPost } from '../../types/Post';
import { Loader } from '../../components/Loader';
import { NotFoundPage } from '../NotFoundPage';

export const PostPage: React.FC = () => {
  const { postId } = useParams();
  const postIdNumeric = Number(postId);

  if (isNaN(postIdNumeric)) {
    return <NotFoundPage />;
  }

  const onePostQuery = useQuery<IPost>({
    queryKey: ['post', postIdNumeric],
    queryFn: async() => {
      return httpCool.get(`/posts/${postIdNumeric}`);
    },
  });

  const postsQuery = useQuery<IPost[]>({
    queryKey: ['posts'],
    queryFn: async() => {
      return httpCool.get(`/posts?newest`);
    },
  });

  return (
    <>
      <section className={styles.section}>
        {onePostQuery.isLoading && <Loader />}

        {onePostQuery.isError && <h3>Помилка при завантаженні новини</h3>}

        {onePostQuery.isSuccess && <Post post={onePostQuery.data} />}
      </section>

      <section className={styles.section}>
        {postsQuery.isLoading && <Loader />}

        {postsQuery.isError && <h3>Помилка при завантаженні новин</h3>}

        {postsQuery.isSuccess && (
          <>
            <h2 className={styles.newsTitle}>
              Інші новини:
            </h2>

            <ul>
              {postsQuery.data.map(post => (
                <PrettyPost
                  key={post.id}
                  post={post}
                />
              ))}
            </ul>
          </>
        )}
      </section>
    </>
  );
};
