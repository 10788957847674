import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AdminPage } from './pages/AdminPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PostPage } from './pages/PostPage';
import { RequireAuth } from './components/RequireAuth/RequireAuth';
import { HomePage } from './pages/HomePage';
import { PageWrapper } from './components/PageWrapper';

export const Root: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PageWrapper />} >
        <Route index element={<HomePage />} />
        <Route path="home" element={<Navigate to="/" />} />

        <Route path="/posts">
          <Route index element={<Navigate to="/" />} />
          <Route path=":postId" element={<PostPage />} />
        </Route>
      </Route>

      <Route path="/login" element={<LoginPage />} />

      <Route path="/admin" element={(
        <RequireAuth ><AdminPage /></RequireAuth>
      )} />
      <Route
        path="/admin/:postId"
        element={(
          <RequireAuth ><AdminPage /></RequireAuth>
        )}
      />

      <Route path="*" element={<NotFoundPage />} />
  </Routes>
  );
};
