/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useMemo } from 'react';
import { User } from '../types/Users';
import { accessTokenService } from '../services/AccessTokenService';
import { authService } from '../services/AuthService';

type AuthContextType = {
  user: User | null;
  isChecked: boolean;
  login: (login: string, password: string) => Promise<void>;
  checkAuth: () => Promise<void>;
  logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  isChecked: false,
  login: () => (Promise.resolve()),
  checkAuth: () => (Promise.resolve()),
  logout: () => {},
});

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isChecked, setIsChecked] = useState(false);

  const login = async(username: string, password: string): Promise<void> => {
    const { user, accessToken } = await authService.login(username, password);

    setUser(user);
    accessTokenService.save(accessToken);
  };

  const checkAuth = async() => {
    try {
      const accessTokenOld = accessTokenService.get();

      if (accessTokenOld) {
        const { user, accessToken } = await authService.refresh(accessTokenOld);

        accessTokenService.save(accessToken);
        setUser(user);
      }
    } catch (error) {
      global.console.log('Користувач не авторизован');
    } finally {
      setIsChecked(true);
    }
  };

  const logout = () => {
    accessTokenService.remove();
    setUser(null);
  };

  const contextValue = useMemo(() => (
    {
      user,
      isChecked,
      login,
      checkAuth,
      logout,
    }
  ), [user, isChecked]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
