import React from 'react';
import styles from './NotFoundPost.module.scss';
import { LoadingButton } from '../LoadingButton';
import cn from 'classnames';

type Props = {
  text: string;
  hasButton?: boolean;
  buttonText?: string,
  onClick?: () => void;
};

export const NotFoundPost: React.FC<Props> = ({
  text,
  hasButton = true,
  buttonText = 'Продовжити',
  onClick = () => {},
}) => {
  return (
    <div className={cn(
      styles.error,
      {
        [styles.fullScreen]: true,
      },
    )}>
      <h3 className={styles.errorMessage}>
        {text}
      </h3>

      {hasButton && (
        <div className={styles.button}>
          <LoadingButton
            text={buttonText}
            isLoading={false}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};
