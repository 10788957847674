import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './PageWrapper.module.scss';

import { Header } from '../Header';
import { Container } from '../Container';
import { Footer } from '../Footer';
import { AuthPanel } from '../AuthPanel';

export const PageWrapper: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <AuthPanel />

      <div className={styles.header}>
        <Header />
      </div>

      <main className={styles.main}>
        <Container>
          <Outlet />
        </Container>
      </main>

      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};
