import React, { useEffect } from 'react';
import { Container } from '../../components/Container';
import { InfinitePostsList } from '../../components/InfinitePostsList';
import { useParams, useNavigate } from 'react-router';

import { PostAdmin } from './components/PostAdmin';
import { PostsProvider } from '../../contexts/PostsContext';
import { FileProvider } from '../../contexts/FileContext';
import { Post } from '../../types/Post';
import { useQuery } from 'react-query';
import { NewPostForm } from './components/NewPostForm';
import { NewPostSkeleton } from './skeletons/NewPostFormSkeleton';
import { NotFoundPost } from '../../components/NotFoundPost';
import { postService } from '../../services/PostService';
import { AuthPanel } from '../../components/AuthPanel';

export const AdminPage: React.FC = () => {
  const postId = Number(useParams().postId);
  const navigate = useNavigate();

  const selectedPostQuery = useQuery<Post>({
    queryKey: ['adminPost', postId],
    queryFn: async() => postService.getById(postId),
    enabled: false,
  });

  useEffect(() => {
    if (isNaN(postId)) {
      navigate('/admin');
    }

    if (postId && !isNaN(postId)) {
      selectedPostQuery.refetch();
    }
  }, [postId]);

  return (
    <PostsProvider>
      <AuthPanel />

      <Container>
        <FileProvider>
          {!postId ? <NewPostForm selectedPost={null} /> : (
            <>
              {selectedPostQuery.isLoading && (<NewPostSkeleton />)}

              {selectedPostQuery.isError && (
                <NotFoundPost
                  text="Не вдалось знайти вибрану новину"
                  buttonText="Створити новину"
                  onClick={() => navigate('/admin')}
                />
              )}

              {selectedPostQuery.isSuccess && (
                <NewPostForm selectedPost={selectedPostQuery.data} />
              )}
            </>
          )}
        </FileProvider>

        <InfinitePostsList postComponent={PostAdmin} />
      </Container>
    </PostsProvider>
  );
};
