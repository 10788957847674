import { createClient } from './createClient';

export const httpCool = createClient();

httpCool.interceptors.request.use(config => {
  config.headers['Cache-Control'] = 'no-cache';

  return config;
});

httpCool.interceptors.response.use(res => res.data);
