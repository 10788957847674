/* eslint-disable max-len */
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './InfinitePostsList.module.scss';

import { Props as PrettyPostProps } from '../../pages/PostPage/components/PrettyPost';
import { Props as PostAdminProps } from '../../pages/AdminPage/components/PostAdmin';

import { Loader } from '../Loader';
import { PostsContext } from '../../contexts/PostsContext';
import { usePage } from '../../hooks/usePage';
import { Post } from '../../types/Post';
import { NotFoundPost } from '../NotFoundPost';

import { postService } from '../../services/PostService';

type Props = {
  postComponent: React.FC<PrettyPostProps> | React.FC<PostAdminProps>
};

export const InfinitePostsList: React.FC<Props> = ({
  postComponent: PostComponent,
}) => {
  const { posts, addBulkPosts } = useContext(PostsContext);
  const { page, increasePage } = usePage(1);

  const postsQuery = useQuery<Post[]>({
    queryKey: ['page', page],
    queryFn: async() => postService.getByPage(page),
    onSuccess: (newPosts) => addBulkPosts(newPosts),
  });

  const hasMore = page <= posts.length / 10;

  return (
    <>
      {postsQuery.isFetching && page === 1 && <Loader />}

      <InfiniteScroll
        dataLength={posts.length}
        next={increasePage}
        hasMore={hasMore}
        loader={<Loader />}
        scrollThreshold={0.75}
        style={{ overflow: 'hidden' }}
      >
        <ul className={styles.list}>
          {posts.map(post => (
            <PostComponent
              key={post.id}
              post={post}
            />
          ))}
        </ul>
      </InfiniteScroll>

      {postsQuery.isError && (
        <NotFoundPost
          text="Виникла помилка при завантаженні новин"
          buttonText="Продовжити"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />
      )}
    </>
  );
};
