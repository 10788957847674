import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './NewPostFormSkeleton.module.scss';

export const NewPostSkeleton: React.FC = () => {
  return (
    <>
      <div className={styles.topInfo}>
        <div>
          <div className={styles.title}>
            <Skeleton />
          </div>
          <div className={styles.titleLow}>
            <Skeleton />
          </div>
        </div>

          <span className={styles.skip}>
            <Skeleton />
          </span>
      </div>

      <div className={styles.form}>
        <div className={styles.titleInput}>
          <Skeleton />
        </div>

        <div className={styles.textInput}>
          <Skeleton />
        </div>

        <div className={styles.fileInput}>
          <Skeleton />
        </div>

        <div className={styles.button}>
          <Skeleton />
        </div>
      </div>
    </>
  );
};
