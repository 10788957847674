import React, { useEffect, useContext } from 'react';
import { Root } from './Roots';
import { AuthContext } from './contexts/AuthContext';
import { Loader } from './components/Loader';

export const App: React.FC = () => {
  const { isChecked, checkAuth } = useContext(AuthContext);

  useEffect(() => {
    checkAuth();
  }, []);

  if (!isChecked) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Loader />
      </div>
    );
  }

  return (
    <Root />
  );
};
