import React from 'react';
import { NotFoundPost } from '../../components/NotFoundPost';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <NotFoundPost
      text="Сторінка не знайдена"
      buttonText="Повернутись"
      onClick={() => navigate(-1)}
    />
  );
};
