import React, { createContext, useMemo, useState } from 'react';

type FileContextType = {
  selectedFile: File | null;
  selectFile: (newFile: File) => void;
  resetFile: () => void;
  error: string,
  setError: (error: string) => void,
  previewURL: string,
  setPreview: (newPreview: string) => void,
};

export const FileContext = createContext<FileContextType>({
  selectedFile: null,
  selectFile: () => {},
  resetFile: () => {},
  error: '',
  setError: () => {},
  previewURL: '',
  setPreview: () => {},
});

type FileProviderType = {
  children: React.ReactNode;
};

export const FileProvider: React.FC<FileProviderType> = ({
  children,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [previewURL, setPreviewURL] = useState<string>('');

  const selectFile = (newFile: File) => {
    setSelectedFile(newFile);
    setPreviewURL(URL.createObjectURL(newFile));
    setError('');
  };

  const resetFile = () => {
    setSelectedFile(null);
    setPreviewURL('');
  };

  const setPreview = (newPreview: string) => {
    resetFile();
    setPreviewURL(newPreview);
  };

  const contextValue = useMemo(() => ({
    selectedFile,
    selectFile,
    resetFile,
    error,
    setError,
    previewURL,
    setPreview,
  }), [selectedFile, error, previewURL]);

  return (
    <FileContext.Provider value={contextValue}>
      {children}
    </FileContext.Provider>
  );
};
