import React from 'react';
import styles from './HomePage.module.scss';
import { InfinitePostsList } from '../../components/InfinitePostsList';
import { PrettyPost } from '../PostPage/components/PrettyPost';
import { PostsProvider } from '../../contexts/PostsContext';

export const HomePage: React.FC = () => {
  return (
    <PostsProvider>
      <div className={styles.section}>
        <InfinitePostsList postComponent={PrettyPost} />
      </div>
    </PostsProvider>
  );
};
