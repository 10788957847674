import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import './styles/global.scss';
import { AuthProvider } from './contexts/AuthContext';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SkeletonTheme baseColor="#ececec" highlightColor="#fafbfc">
          <App />
        </SkeletonTheme>
      </AuthProvider>
    </QueryClientProvider>
  </Router>,
);
