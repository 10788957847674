/* eslint-disable max-len */
import { httpCool } from '../api/httpCool';
import { User } from '../types/Users';

type AuthPayload = {
  user: User;
  accessToken: string;
};

async function login(username: string, password: string): Promise<AuthPayload> {
  return httpCool.post('/login', { username, password });
}

async function refresh(accessToken: string): Promise<AuthPayload> {
  return httpCool.post('/refresh', { accessToken });
}

export const authService = {
  login,
  refresh,
};
