import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { Picture } from '../Picture';

export const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <Link
        to="/"
        className={styles.link}
      >
        <Picture
          className={styles.logo}
          src="/icons/dnipro-online-logo.svg"
          alt="Dnipro logo"
        />
      </Link>
    </header>
  );
};
