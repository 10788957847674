import { httpClient } from '../api/httpClient';

async function upload(file: File): Promise<string> {
  const formData = new FormData();

  formData.append('file', file);

  const imageUploadResponse: { path: string } = await httpClient.post(
    '/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return imageUploadResponse.path;
}

export const imageService = { upload };
