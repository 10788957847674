import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

import styles from './Loader.module.scss';

type Props = {
  width?: number;
  height?: number;
};

export const Loader: React.FC<Props> = ({ width = 50, height = 50 }) => (
  <div className={styles.loader}>
    <ThreeCircles
      height={height}
      width={width}
      color="#4fa94d"
      ariaLabel="three-circles-rotating"
      outerCircleColor="#89939A"
      innerCircleColor="#B4BDC4"
      middleCircleColor="#FEC132"
    />
  </div>
);
