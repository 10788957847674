/* eslint-disable max-len */
import React from 'react';
import styles from './Post.module.scss';
import { Post as IPost } from '../../../../types/Post';
import { getFormattedTime } from '../../../../helpers/getFormattedTime';
import { Picture } from '../../../../components/Picture';

type Props = {
  post: IPost;
}

export const Post: React.FC<Props> = ({ post }) => {
  const formattedData = getFormattedTime(post.date);
  const paragraphs = post.text.split('\n').filter(p => p !== '');

  return (
    <>
      <p className={styles.title}>
        {post.title}
      </p>

      <span className={styles.date}>
        {formattedData}
      </span>

      <Picture
        className={styles.image}
        src={post.image}
        alt="main post image"
      />

      <article>
        {paragraphs.map(paragrph => (
          <p key={paragrph.slice(10)} className={styles.paragraph}>
            {paragrph}
          </p>
        ))}
      </article>
    </>
  );
};
