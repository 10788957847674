import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './FileInput.module.scss';
import { useDropzone, FileRejection } from 'react-dropzone';
import { Picture } from '../../../../components/Picture';
import { FileContext } from '../../../../contexts/FileContext';

// use it component with FileContext only
export const FileInput: React.FC = React.memo(() => {
  const {
    selectedFile,
    previewURL,
    setError,
    selectFile,
  } = useContext(FileContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        setError('Виберіть файл у форматі JPEG або PNG');

        return;
      }

      if (acceptedFiles.length > 0) {
        selectFile(acceptedFiles[0]);
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className={cn({
        [styles.mainArea]: !previewURL,
      })}
    >
      <input {...getInputProps()} />

      {!previewURL && (
        <p>Перетягніть або клікніть, щоб вибрати файл</p>
      )}

      {previewURL && (
        <div className={styles.previewContainer}>
          <div className={styles.info}>
            <span className={styles.fileName}>{selectedFile?.name}</span>

            <span className={styles.tip}>Натисніть щоб змінити</span>
          </div>

          {selectedFile
            ? (
              <img
                className={styles.image}
                src={previewURL}
                alt="File Preview"
              />
            ) : (
              <Picture
                className={styles.image}
                src={'/' + previewURL}
                alt="File Preview"
              />
            )}
        </div>
      )}
    </div>
  );
});
