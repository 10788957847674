import React, { useState, InputHTMLAttributes } from 'react';
import styles from './FormInput.module.scss';

type Props = { isSubmitted?: boolean } & InputHTMLAttributes<HTMLInputElement>;

export const FormInput: React.FC<Props> = React.memo(({
  isSubmitted = false,
  ...props
}) => {
  const [touched, setToched] = useState(false);
  const hasError = (touched || isSubmitted) && props.required && !props.value;

  return (
    <div className={styles.field}>
      <input
        type="text"
        className={styles.input}
        value={props.value}
        onBlur={() => setToched(true)}
        {...props}
      />

      {hasError && (
        <div className={styles.validation}>
          {`Заповніть ${props.placeholder?.toLowerCase()}`}
        </div>
      )}
    </div>
  );
});
