import React, { useState, useMemo, useCallback } from 'react';
import { Post } from '../types/Post';

type PostsContextType = {
  posts: Post[];
  setPosts: React.Dispatch<React.SetStateAction<Post[]>>;
  addPost: (newPost: Post) => void;
  removePost: (postId: number) => void;
  addBulkPosts: (newPosts: Post[]) => void;
  updatePost: (updatedPost: Post) => void;
  findPost: (postId: number) => Post | null;
};

export const PostsContext = React.createContext<PostsContextType>({
  posts: [],
  setPosts: () => {},
  addPost: () => {},
  removePost: () => {},
  addBulkPosts: () => {},
  updatePost: () => {},
  findPost: () => null,
});

type PostsProviderType = {
  children: React.ReactNode;
};

export const PostsProvider: React.FC<PostsProviderType> = ({ children }) => {
  const [posts, setPosts] = useState<Post[]>([]);

  const addPost = useCallback((newPost: Post) => {
    setPosts(prevPosts => [newPost, ...prevPosts]);
  }, [posts]);

  const addBulkPosts = useCallback((newPosts: Post[]) => {
    setPosts(prevPosts => [...prevPosts, ...newPosts]);
  }, [posts]);

  const removePost = useCallback((postId: number) => {
    setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
  }, [posts]);

  const updatePost = useCallback((updatedPost: Post) => {
    setPosts(prevPosts => prevPosts.map(post => (
      post.id === updatedPost.id
        ? updatedPost
        : post
    )));
  }, [posts]);

  const findPost = useCallback((postId: number): Post | null => {
    return posts.find(post => post.id === postId) ?? null;
  }, [posts]);

  const contextValue = useMemo(() => (
    {
      posts,
      setPosts,
      removePost,
      addPost,
      addBulkPosts,
      updatePost,
      findPost,
    }
  ), [posts]);

  return (
    <PostsContext.Provider value={contextValue}>
      {children}
    </PostsContext.Provider>
  );
};
