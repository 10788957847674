import { useState } from 'react';

type usePageReturnType = {
  page: number;
  increasePage: () => void;
}

export const usePage = (startPage: number): usePageReturnType => {
  const [page, setPage] = useState(startPage);

  const increasePage = () => setPage(prevPage => prevPage + 1);

  return {
    page,
    increasePage,
  };
};
