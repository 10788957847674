import React from 'react';
import styles from './PrettyPost.module.scss';
import { Post } from '../../../../types/Post';
import { getFormattedTime } from '../../../../helpers/getFormattedTime';
import { Picture } from '../../../../components/Picture';
import { PageLink } from '../../../../components/PageLink';

export type Props = {
  post: Post;
};

export const PrettyPost: React.FC<Props> = ({ post }) => {
  return (
    <li className={styles.item}>
      <Picture
        className={styles.image}
        src={'/' + post.image}
        alt="list news image"
      />

      <div className={styles.info}>
        <PageLink
          className={styles.title}
          href={`/posts/${post.id}`}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {post.title}
        </PageLink>
        <span className={styles.date}>
          {getFormattedTime(post.date)}
        </span>
      </div>
    </li>
  );
};
